import React from 'react'
import Layout from '../../layouts/Layout'
import { Link } from 'gatsby'
import BasicHero from '../../components/Hero/BasicHero'
import { Container } from 'theme-ui'

const DownloadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="35"
    viewBox="0 0 26 35"
    sx={{
      mr: 3,
    }}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_8688"
          data-name="Rectangle 8688"
          width="26"
          height="35"
          transform="translate(0 0.003)"
          sx={{
            fill: 'brand.matisse',
          }}
        />
      </clipPath>
    </defs>
    <g id="Group_3569" data-name="Group 3569" transform="translate(0 -0.003)">
      <g id="Group_3568" data-name="Group 3568" clip-path="url(#clip-path)">
        <path
          id="Path_5993"
          data-name="Path 5993"
          d="M26.443,9.194a.7.7,0,0,0-.2-.495L17.728.2a.7.7,0,0,0-.534-.2V0H1.951A1.954,1.954,0,0,0,0,1.951v31.1A1.953,1.953,0,0,0,1.951,35H24.494a1.953,1.953,0,0,0,1.951-1.951V9.194Zm-8.51-6.81,6.123,6.111h-5.57a.554.554,0,0,1-.553-.553ZM24.494,33.6H1.951a.554.554,0,0,1-.553-.553V1.951A.554.554,0,0,1,1.951,1.4H16.536V7.941a1.953,1.953,0,0,0,1.951,1.951h6.56V33.051a.554.554,0,0,1-.553.553"
          sx={{
            fill: 'brand.matisse',
          }}
        />
        <path
          id="Path_5994"
          data-name="Path 5994"
          d="M78.6,126.7v6.551h1.02l-3.183,3.735-3.183-3.735h1.02V126.7Zm1.4-1.4H72.874v6.551h-2.65l1.964,2.3,3.183,3.735,1.064,1.248,1.064-1.248,3.183-3.735,1.964-2.3H80V125.3Z"
          transform="translate(-63.213 -112.791)"
          sx={{
            fill: 'brand.matisse',
          }}
        />
        <path
          id="Path_5995"
          data-name="Path 5995"
          d="M71.442,267.442H56.225v-3.514h1.4v2.117H70.045v-2.117h1.4Z"
          transform="translate(-50.611 -237.577)"
          sx={{
            fill: 'brand.matisse',
          }}
        />
      </g>
    </g>
  </svg>
)

export default function news() {
  return (
    <Layout theme={'light'}>
      <BasicHero
        heading="News"
        bgColor="#F5F1EE"
        theme="light"
        align="center"
      />
      <Container
        sx={{
          flexDirection: 'column',
        }}
      >
        <div
          sx={{
            padding: '20px 20px',
            pb: 5,
            borderBottom: 'solid 1px #707070',
          }}
        >
          <Link to="/news/Rebrand-Attain-Finance" sx={{ color: 'black!important' }}>
            <p sx={{ color: 'brand.matisse', fontSize: '25px', my: 3 }}>
              February 5, 2025
            </p>
            <p sx={{ fontSize: '38px', mb: 0 }}>
              Curo Group Rebrands as Attain Finance
            </p>
          </Link>
        </div>
        <div sx={{ padding: '20px 20px', borderBottom: 'solid 1px #707070' }}>
          <a
            href="/pdf/Form-8937-with-Attachments.pdf"
            sx={{ color: 'black!important' }}
          >
            <p sx={{ color: 'brand.matisse', fontSize: '25px', my: 3 }}>
              January 10, 2025
            </p>
            <p sx={{ fontSize: '38px', mb: 0 }}>Form 8937</p>
            <p
              sx={{
                fontSize: '25px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {DownloadIcon} Download PDF
            </p>
          </a>
        </div>
        <div sx={{ padding: '10px 20px' }}>
          <Link
            to="/news/Curo-Emerges-From-Chapter-11"
            sx={{ color: 'black!important' }}
          >
            <p sx={{ color: 'brand.matisse', fontSize: '25px', my: 3 }}>
              July 19, 2024
            </p>
            <p sx={{ fontSize: '38px' }}>
              Curo Emerges from Chapter 11 Better Positioned for Long-Term
              Profitable Growth
            </p>
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

// export const Head = ({ data }) => {
//   const page = data.contentfulPageJson
//   return <Seo title={page.pageTitle} />
// }
